@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap");

body {
  font-family: "Inter", sans-serif;
  @apply bg-wline;
}
